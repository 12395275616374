import { Box, Skeleton } from '@mui/material';
import { TextSkeleton } from '@/components/common/TextSkeleton/TextSkeleton';
import { CardContent, Container, Row } from './GridCardSkeleton.styles';
import { StyledCardActionArea } from '../CardMediaSelect/CardMediaSelect.styles';
import { ALBUM_IMG_RATIO } from '../AlbumGridCard/AlbumGridCard';
import { MEDIA_IMG_RATIO } from '../MediaGridCard/MediaGridCard';

export const COVER_FALLBACK_URL = '/images/EmptyAlbum.svg';

export function AlbumGridCardSkeleton() {
  return (
    <Container className="skeleton-container" $isAlbumCard>
      <StyledCardActionArea
        component="div"
        $albumShadow
        style={{
          width: '100%',
          aspectRatio: ALBUM_IMG_RATIO,
        }}
      >
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height="100%"
          style={{
            borderRadius: 12,
          }}
        />
      </StyledCardActionArea>
      <Box
        height={50}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Skeleton variant="text" width={100} height={30} />
        <Skeleton variant="text" width={140} height={30} />
      </Box>
    </Container>
  );
}

export function MediaGridCardSkeleton() {
  return (
    <Container>
      <StyledCardActionArea
        component="div"
        style={{
          width: '100%',
          aspectRatio: MEDIA_IMG_RATIO,
        }}
      >
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height="100%"
          style={{
            borderRadius: 12,
          }}
        />
      </StyledCardActionArea>
      <CardContent>
        <Row>
          <TextSkeleton width="80%" height="30px" />
          <Skeleton
            variant="rectangular"
            width="30%"
            height="23px"
            sx={{ borderRadius: '12px' }}
          />
        </Row>
        <TextSkeleton width="40%" height="22px" />
        <TextSkeleton width="25%" height="20px" />
      </CardContent>
    </Container>
  );
}
