import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { TwicImg } from '@twicpics/components/react';
import { getLocalTimeZone } from '@internationalized/date';
import { useLicence, useWorkspaceCredits } from '@/api/meero-suite/billing';
import { useDialog } from '@/context/DialogContext';
import { assets } from '@/config/assets-links';
import { createDateFormatter } from '@/utils/date-formatter';
import { getAppConfig } from '@/config/env';
import { trackButtonClicked } from '@/lib/analytics';
import { SegmentPageLabel } from '@/lib/segment-label/page-label';

type RestrictionDialogStatus =
  | 'free-trial-no-credit'
  | 'premium-no-credit'
  | 'trial-expired'
  | 'active-but-cancelled-no-credit'
  | 'inactive';

type ImportType = 'new-property' | 'existing-property';

export function useImportPhotosHandlers({
  trackingOrigin: currentPage,
}: {
  trackingOrigin: SegmentPageLabel;
}) {
  const { push } = useRouter();
  const { t } = useTranslation('importPhotos');

  const [openImportDialog, setOpenImportDialog] = useState(false);

  const { openDialog, dismissDialog } = useDialog();
  const { data: licence } = useLicence();
  const { enableBillingCredits } = getAppConfig();
  const { data: propertyCredit } = useWorkspaceCredits(enableBillingCredits);
  const { locale } = useRouter();
  const formatter = createDateFormatter('month-day-two-digit', locale);

  const handleOpenImportDialog = (importType: ImportType) => {
    if (
      licence === undefined ||
      (enableBillingCredits && propertyCredit === undefined)
    ) {
      throw new Error('Cannot check the licence or credits, aborting.');
    }
    const { isPremium, status, expirationDate } = licence.computed;

    let dialogStatus: RestrictionDialogStatus = 'inactive';

    if (status === 'trialing' || isPremium) {
      if (
        !enableBillingCredits ||
        (propertyCredit !== undefined && propertyCredit.propertyCredit > 0) ||
        importType === 'existing-property'
      ) {
        setOpenImportDialog(true);

        return;
      }

      switch (status) {
        case 'trialing':
          dialogStatus = 'free-trial-no-credit';
          break;
        case 'active-but-cancelled':
          dialogStatus = 'active-but-cancelled-no-credit';
          break;
        default:
          dialogStatus = 'premium-no-credit';
      }
    }

    if (status === 'trial-expired' || status === 'inactive') {
      dialogStatus = status;
    }

    trackButtonClicked('Pop-up Limitations Display', {
      page: currentPage,
      type: 'create property blocked',
      licence_status: status,
    });

    openDialog({
      headerImgSlot: (
        <TwicImg
          src={assets.cdnImageAssets.restrictionDialogHeaderImage}
          ratio="2"
        />
      ),
      variant: 'restriction',
      title: t(`restrictionDialog.${dialogStatus}.title`),
      message: t(`restrictionDialog.${dialogStatus}.message`, {
        monthlyProperties: licence.billingProduct.monthlyPropertyCredit,
        renewalDate: formatter.format(
          expirationDate.toDate(getLocalTimeZone())
        ),
        endDate: formatter.format(expirationDate.toDate(getLocalTimeZone())),
      }),
      onConfirm: () => {
        void push('/subscription').then(dismissDialog);
        trackButtonClicked('Subscribe From Pop-up Clicked', {
          page: currentPage,
        });
      },
      onDismiss: dismissDialog,
      onClose: dismissDialog,
      ctaButtonLabel: t(`restrictionDialog.${dialogStatus}.ctaButton`),
    });

    return;
  };

  return {
    openImportDialog,
    handleOpenImportDialog,
    handleCloseImportDialog: () => setOpenImportDialog(false),
  };
}
