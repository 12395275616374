import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { AutoFixHighIcon, Box, Typography } from '@/components/common';
import { TypographyEllipsis } from '@/components/common/TypographyEllipsis/TypographyEllipsis';
import { trackButtonClicked } from '@/lib/analytics';
import { getSegmentPageLabel } from '@/lib/segment-label/page-label';
import { useParentPageUrl } from '@/hooks/use-parent-page-url';
import { createDateFormatter } from '@/utils/date-formatter';
import { parseAndFormat } from '@/utils/date';
import { MediaFormatted } from '@/types/Media/MediaFormatted';
import {
  CardContainer,
  CardDescription,
  CardToolsWrapper,
  EditorButton,
  MediaNameHeader,
} from './MediaGridCard.styles';
import { MediaStatusTag } from '../MediaStatusTag/MediaStatusTag';
import { ImageBeforeAfter } from '../ImageBeforeAfter/ImageBeforeAfter';
import { CardMediaSelect } from '../CardMediaSelect/CardMediaSelect';
import { RoundedTwicImg } from '../AlbumGridCard/AlbumGridCard.styles';

export const MEDIA_IMG_RATIO = 1.15;

type MediaGridCardProps = {
  media: MediaFormatted;
  onMediaClick: () => void;
  trackToggleEvent?: (version: 'before' | 'after') => void;
  selectable?: boolean;
  isSelecting?: boolean;
  isSelected?: boolean;
  onSelect?: () => void;
  onUnselect?: () => void;
};

export default function MediaGridCard({
  media,
  onMediaClick,
  trackToggleEvent,
  selectable = false,
  isSelected = false,
  isSelecting = false,
  onSelect,
  onUnselect,
}: MediaGridCardProps) {
  const { t } = useTranslation('library');
  const { t: tCommon } = useTranslation('common');
  const router = useRouter();
  const { setParentPageUrl } = useParentPageUrl('editor', media.uuid);

  const [showBefore, setShowBefore] = useState(false);

  const dateFormatter = createDateFormatter(
    'year-month-day-short',
    router.locale
  );

  const toggleMediaSelection = (select: boolean) => {
    if (!media.isProcessed) return;
    select ? onSelect?.() : onUnselect?.();
  };

  // We hide the checkbox if the user is on the before version and not yet selecting
  const hideSelectCheckbox = showBefore && !isSelecting;

  const editorLink = `/editor?m=${media.uuid}&tool=magic-eraser`;

  return (
    <CardContainer>
      <CardToolsWrapper>
        <CardMediaSelect
          variant="media"
          onClick={onMediaClick}
          selectable={selectable && !hideSelectCheckbox}
          isSelected={isSelected}
          isSelecting={isSelecting}
          toggleSelection={toggleMediaSelection}
          disabledTooltip={
            media.isProcessed ? undefined : t('tooltip.notEnhanced')
          }
        >
          {media.isProcessed ? (
            <ImageBeforeAfter
              showBefore={showBefore}
              setShowBefore={setShowBefore}
              isSelecting={isSelecting}
              s3KeyBefore={media.firstVersion.s3Key}
              s3KeyAfter={media.currentVersion.s3Key}
              alt=""
              ratio={MEDIA_IMG_RATIO}
              trackToggleEvent={trackToggleEvent}
            />
          ) : (
            <RoundedTwicImg
              src={media.currentVersion.s3Key}
              mode="cover"
              ratio={MEDIA_IMG_RATIO}
              $darken={!media.isProcessed}
            />
          )}
        </CardMediaSelect>
        {media.isProcessed && (
          <Box
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            {!isSelecting && (
              <EditorButton
                size="small"
                value="editor"
                onClick={(e) => {
                  e.stopPropagation();
                  setParentPageUrl(router.asPath);
                  // TODO update to "editor"
                  trackButtonClicked('Magic Eraser CTA Clicked', {
                    page: getSegmentPageLabel(router.asPath, router.query),
                    media_uuid: media.uuid,
                  });
                  void router.push(editorLink);
                }}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <AutoFixHighIcon fontSize="small" />
                <Typography>{tCommon('mediaEditCta')}</Typography>
              </EditorButton>
            )}
          </Box>
        )}
      </CardToolsWrapper>
      <CardDescription>
        <MediaNameHeader>
          <TypographyEllipsis variant="subtitle2" fontWeight={600}>
            {media.name}
          </TypographyEllipsis>
          <MediaStatusTag
            status={media.status}
            label={tCommon(`mediaStatus.${media.status}`)}
          />
        </MediaNameHeader>
        <Typography fontSize={14} lineHeight="18px" color="textSecondary">
          {parseAndFormat(media.uploadDate, dateFormatter)}
        </Typography>
      </CardDescription>
    </CardContainer>
  );
}
