import styled, { css } from '@/utils/styled';
import {
  colors,
  fontSize,
  fontWeight,
  getMeeSpacing,
  lineHeight,
} from '@/theme';

const MAX_WIDTH_TAG_CONTAINER = '120px';

export type TagIntegralType =
  | 'primary'
  | 'info'
  | 'error'
  | 'warning'
  | 'success';

export const Container = styled('div')<{
  $type: TagIntegralType;
  $size: 'small' | 'medium' | 'fit-content';
  $isReverse: boolean;
  $isEllipsis: boolean;
  onClick?: () => void;
  $color?: string;
  $backgroundColor?: string;
  $borderColor?: string;
  $isBold: boolean;
  $darkMode: boolean;
  $hasBorder: boolean;
}>`
  display: flex;
  align-items: center;
  flex-direction: ${({ $isReverse }) => ($isReverse ? 'row-reverse' : 'row')};
  width: max-content;
  border-radius: 100px;
  box-sizing: border-box;
  white-space: nowrap;
  border-width: ${({ $hasBorder }) => ($hasBorder ? '1px' : '0px')};
  border-style: solid;
  font-weight: ${({ $isBold }) =>
    $isBold ? fontWeight.bold : fontWeight.normal};

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `};

  ${({ $isEllipsis }) =>
    $isEllipsis &&
    css`
      max-width: ${MAX_WIDTH_TAG_CONTAINER};
    `};

  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          font-size: ${fontSize.small};
          line-height: ${lineHeight.xSmaller};
          padding: ${getMeeSpacing(0.25, 0.75)};
          gap: ${getMeeSpacing(0.25)};
        `;
      default:
        return css`
          font-size: ${fontSize.small};
          line-height: ${lineHeight.xSmaller};
          padding: ${getMeeSpacing(0.5, 0.75)};
          gap: ${getMeeSpacing(0.5)};
        `;
    }
  }}

  ${({ $type, $darkMode }) => {
    switch ($type) {
      case 'primary':
      default:
        return css`
          color: ${$darkMode ? colors.white : colors.miracle};
          background-color: ${$darkMode ? colors.miracle : colors.gramShair};
          border-color: ${$darkMode ? colors.miracle : colors.gramShair};
        `;
      case 'info':
        return css`
          color: ${$darkMode ? colors.white : colors.amnesiaBlue};
          background-color: ${$darkMode ? colors.epicBlue : colors.cottonBoll};
          border-color: ${$darkMode ? colors.epicBlue : colors.cottonBoll};
        `;
      case 'success':
        return css`
          color: ${$darkMode ? colors.white : colors.hollyGreen};
          background-color: ${$darkMode
            ? colors.chromophobiaGreen
            : colors.frostyMint};
          border-color: ${$darkMode
            ? colors.chromophobiaGreen
            : colors.frostyMint};
        `;
      case 'error':
        return css`
          color: ${$darkMode ? colors.white : colors.ruralRed};
          background-color: ${$darkMode
            ? colors.manganeseRed
            : colors.cherubic};
          border-color: ${$darkMode ? colors.manganeseRed : colors.cherubic};
        `;
      case 'warning':
        return css`
          color: ${$darkMode ? colors.white : colors.copiousCaramel};
          background-color: ${$darkMode
            ? colors.dodgerollGold
            : colors.fatBack};
          border-color: ${$darkMode ? colors.dodgerollGold : colors.fatBack};
        `;
    }
  }}

  ${({ $color }) =>
    $color !== undefined &&
    css`
      color: ${$color};
    `}

  ${({ $backgroundColor }) =>
    $backgroundColor !== undefined &&
    css`
      background-color: ${$backgroundColor};
    `}

  ${({ $borderColor }) =>
    $borderColor !== undefined &&
    css`
      border-color: ${$borderColor};
    `}
`;

export const IconContainer = styled('div')<{
  $size: 'small' | 'medium' | 'fit-content';
}>`
  color: inherit;
  display: flex;
  align-items: center;

  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          min-width: 12px;
          min-height: 12px;
          max-width: 12px;
          max-height: 12px;
        `;
      case 'fit-content':
        return;
      case 'medium':
      default:
        return css`
          min-width: 16px;
          min-height: 16px;
          max-width: 16px;
          max-height: 16px;
        `;
    }
  }}

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const TextContainer = styled('div')<{ $isEllipsis: boolean }>`
  ${({ $isEllipsis }) =>
    $isEllipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};
`;

export const EndText = styled('span')`
  font-weight: ${fontWeight.normal};
  white-space: nowrap;
`;
