import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { PermMediaOutlined } from '@mui/icons-material';
import { Box, Typography } from '@/components/common';
import { TypographyEllipsis } from '@/components/common/TypographyEllipsis/TypographyEllipsis';
import { trackButtonClicked } from '@/lib/analytics';
import { getSegmentPageLabel } from '@/lib/segment-label/page-label';
import { assets } from '@/config/assets-links';
import { useParentPageUrl } from '@/hooks/use-parent-page-url';
import { createDateFormatter } from '@/utils/date-formatter';
import { parseAndFormat } from '@/utils/date';
import {
  AlbumNameHeader,
  AlbumStatusLabel,
  CardContainer,
  CardDescription,
  RoundedTwicImg,
} from './AlbumGridCard.styles';

import { CardMediaSelect } from '../CardMediaSelect/CardMediaSelect';
import { OptionMenu } from './OptionMenu/OptionMenu';

export const ALBUM_IMG_RATIO = 1.17;
interface AlbumGridCardProps {
  cardType?: 'default' | 'home';
  coverS3Key: string | null;
  albumName: string;
  albumSize: number;
  albumDate: string;
  albumUuid: string;
  isFullyDelivered: boolean;
  isAlbumNew?: boolean;
  href: string;
  selectable?: boolean;
  isSelecting?: boolean;
  selected?: boolean;
  onSelect?: () => void;
  onUnselect?: () => void;
  onDelete: () => Promise<void>;
}

export function AlbumGridCard({
  cardType = 'default',
  coverS3Key,
  albumName,
  albumDate,
  albumSize,
  albumUuid,
  isFullyDelivered,
  isAlbumNew = false,
  href,
  selectable = false,
  isSelecting = false,
  selected = false,
  onSelect,
  onUnselect,
  onDelete,
}: AlbumGridCardProps) {
  const { t } = useTranslation('albumCard');
  const { push, asPath, query, locale } = useRouter();

  const dateFormatter = createDateFormatter('year-month-day-short', locale);
  const { setParentPageUrl } = useParentPageUrl('album', albumUuid);

  const trackAlbumClicked = () => {
    setParentPageUrl(asPath);
    trackButtonClicked('Album Clicked', {
      page: getSegmentPageLabel(asPath, query),
      album_uuid: albumUuid,
      nb_media: albumSize,
    });
  };

  const toggleAlbumSelection = (select: boolean) => {
    if (!isFullyDelivered) return;
    select ? onSelect?.() : onUnselect?.();
  };

  return (
    <CardContainer className="album-card-container">
      <CardMediaSelect
        variant="album"
        selectable={cardType === 'home' ? false : selectable}
        isSelected={selected}
        isSelecting={isSelecting}
        toggleSelection={toggleAlbumSelection}
        disabledTooltip={
          !isFullyDelivered ? t('tooltip.processing') : undefined
        }
        onClick={() => {
          trackAlbumClicked();
          void push(href);
        }}
      >
        <RoundedTwicImg
          src={coverS3Key ?? assets.cdnImageAssets.fallbackMedia}
          mode="cover"
          ratio={ALBUM_IMG_RATIO}
          preTransform="quality=100"
          $showProcessing={isSelecting && !isFullyDelivered}
        />
        {isAlbumNew && <AlbumStatusLabel>{t('badge.new')}</AlbumStatusLabel>}
      </CardMediaSelect>
      <CardDescription>
        <AlbumNameHeader>
          <TypographyEllipsis variant="subtitle1" fontWeight={600}>
            <Link href={href} onClick={trackAlbumClicked}>
              {albumName}
            </Link>
          </TypographyEllipsis>
          <OptionMenu onDelete={onDelete} albumUuid={albumUuid} />
        </AlbumNameHeader>
        <Typography
          display="flex"
          fontSize={14}
          lineHeight="16px"
          color="textSecondary"
          alignItems="center"
          whiteSpace="nowrap"
          columnGap="0.75ch"
        >
          {parseAndFormat(albumDate, dateFormatter)}
          <span aria-hidden="true">·</span>
          <MediaCount
            count={albumSize}
            title={t('nbMedia', { count: albumSize })}
          />
        </Typography>
      </CardDescription>
    </CardContainer>
  );
}

function MediaCount({ count, title }: { count: number; title: string }) {
  return (
    <Box
      component="span"
      display="inline-flex"
      alignItems="center"
      gap="0.75ch"
      title={title}
    >
      <PermMediaOutlined sx={{ width: 16, height: 16 }} />
      {count}
    </Box>
  );
}
