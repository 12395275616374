import { colors, fontWeight, getMeeSpacing } from '@/theme';
import styled from '@/utils/styled';
import { ButtonMeero } from '../ButtonMeero/ButtonMeero';

export const CardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: ${getMeeSpacing(2)};
`;

export const EditorButton = styled(ButtonMeero)`
  display: flex;
  position: absolute;
  background-color: ${colors.primary};
  opacity: 0;
  border-radius: 8px;
  bottom: ${getMeeSpacing(1)};
  gap: ${getMeeSpacing(1)};
  width: 95%;
  transition:
    all ease-in 500ms,
    opacity ease-in 100ms;
  .MuiTypography-root {
    font-weight: ${fontWeight.semiBold};
  }
`;

export const CardToolsWrapper = styled('div')`
  position: relative;

  &:hover {
    ${EditorButton} {
      opacity: 1;
    }
  }
`;

export const CardDescription = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: ${getMeeSpacing(0.5)};
`;

export const MediaNameHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
