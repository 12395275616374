import { SaveAsOutlined } from '@mui/icons-material';
import { TagIntegral } from '@/components/common/TagIntegral/TagIntegral';
import {
  ErrorIcon,
  OutboundIcon,
  SuccessIcon,
  SyncIcon,
} from '@/components/common';
import { MediaStatus } from '@/types/Media/Media';
import { TagIntegralType } from '../TagIntegral/TagIntegral.styles';

interface MediaStatusTagProps {
  status: MediaStatus;
  label: string;
  isEllipsis?: boolean;
}

export const MediaStatusTag = ({
  status,
  label,
  isEllipsis,
  ...rest
}: MediaStatusTagProps) => {
  return (
    <TagIntegral
      content={label}
      icon={<IconStatusTag status={status} />}
      type={getTagType(status)}
      isEllipsis={isEllipsis}
      {...rest}
    />
  );
};

interface IconStatusTagProps {
  status: MediaStatus;
}
const IconStatusTag = ({ status }: IconStatusTagProps) => {
  switch (status) {
    case 'manual_moderation_required':
      return <OutboundIcon />;
    case 'rejected':
      return <ErrorIcon />;
    case 'validated':
    case 'enhanced':
      return <SuccessIcon />;
    case 'edited':
      return <SaveAsOutlined />;
    case 'quality_score_processing':
    case 'ai_moderation_processing':
    case 'processing':
    case 'final_check_processing':
    default:
      return (
        <SyncIcon
          sx={{
            animation: 'spin 2s linear infinite',
            '@keyframes spin': {
              '0%': {
                transform: 'rotate(360deg)',
              },
              '100%': {
                transform: 'rotate(0deg)',
              },
            },
          }}
        />
      );
  }
};

function getTagType(status: MediaStatus): TagIntegralType {
  switch (status) {
    case 'queued_for_editing':
      return 'primary';
    case 'processing':
    case 'quality_score_processing':
    case 'ai_moderation_processing':
    case 'final_check_processing':
    case 'edited':
      return 'info';
    case 'manual_moderation_required':
    case 'final_check_required':
      return 'warning';
    case 'rejected':
      return 'error';
    case 'validated':
    case 'enhanced':
      return 'success';
  }
}
