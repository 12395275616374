import { ImgComparisonSlider } from '@img-comparison-slider/react';
import { useTranslation } from 'next-i18next';
import { TwicImg } from '@twicpics/components/react';
import { ComponentProps } from 'react';
import styled, { css } from '@/utils/styled';
import { getMeeSpacing } from '@/theme';

type TwicImgProps = ComponentProps<typeof TwicImg>;
type ImageComparisonSliderProps = {
  firstImageS3Key: string;
  secondImageS3Key: string | undefined;
  twicImgProps: Omit<TwicImgProps, 'src'>;
};

const StyledImgComparisonSlider = styled(ImgComparisonSlider)<{
  $hideDivider?: boolean;
}>`
  width: 100%;
  figure {
    margin: 0;
  }

  figure[slot='first'] figcaption,
  figure[slot='second'] figcaption {
    border-radius: 100px;
    background: rgba(35, 38, 49, 0.4);
    color: white;
    padding: ${getMeeSpacing(0.5, 2)};
    position: absolute;
    top: 12px;
    line-height: 100%;
  }

  figure[slot='first'] figcaption {
    left: 12px;
  }

  figure[slot='second'] figcaption {
    right: 12px;
  }

  --divider-width: 2px;
  --default-handle-opacity: 1;

  ${({ $hideDivider = false }) => {
    if ($hideDivider) {
      return css`
        --divider-width: 0;
        --default-handle-opacity: 0;
        pointer-events: none;

        figure figcaption {
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
        }
      `;
    }
  }}
`;

export function ImageComparisonSlider({
  firstImageS3Key,
  secondImageS3Key,
  twicImgProps,
}: ImageComparisonSliderProps) {
  const { t } = useTranslation('common');

  const value = secondImageS3Key === undefined ? 100 : 33;

  return (
    <StyledImgComparisonSlider
      value={value}
      $hideDivider={secondImageS3Key === undefined}
    >
      <figure slot="first">
        <TwicImg src={firstImageS3Key} {...twicImgProps} />
        <figcaption>{t('before')}</figcaption>
      </figure>
      <figure slot="second">
        <TwicImg
          src={secondImageS3Key ?? firstImageS3Key}
          mode="contain"
          {...twicImgProps}
        />
        <figcaption>{t('after')}</figcaption>
      </figure>
    </StyledImgComparisonSlider>
  );
}
