import styled from '@/utils/styled';
import { Tab, Tabs } from '@/components/common';
import { colors } from '@/theme';

export const SegmentedPicker = styled(Tabs)`
  width: 100%;
  padding: 2px;
  border-radius: 8px;
  min-height: 0px;
  background-color: ${colors.translucentWhite};

  &:has(.Mui-disabled) {
    opacity: 0.4;
  }

  .MuiTabs-indicator {
    height: 100%;
    border-radius: 8px;
    background-color: ${colors.white};
    font-weight: bold;
    z-index: 0;
    box-shadow: 0px 4px 8px 0px rgba(18, 21, 24, 0.06);
    border: 1px solid ${colors.translucentWhite};
  }
`;

export const SegmentedPickerButton = styled(Tab)`
  color: black;
  font-weight: inherit;
  text-transform: none;
  min-width: 0px;
  min-height: 0px;
  width: 50%;
  padding: 8px;
  z-index: 1;

  &.Mui-selected {
    color: black;
    font-weight: bold;
  }

  &.Mui-disabled {
    color: inherit;
  }
`;
SegmentedPickerButton.defaultProps = {
  disableRipple: true,
};
