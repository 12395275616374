import { useTranslation } from 'next-i18next';
import { enqueueSnackbar } from 'notistack';
import { useDialog } from '@/context/DialogContext';
import { BaseMenu, MoreOptionsButton } from '@/components/common/Menu/BaseMenu';
import { trackButtonClicked } from '@/lib/analytics';

type OptionMenuProps = {
  albumUuid?: string;
  onDelete: () => Promise<void>;
};

export function OptionMenu({ albumUuid, onDelete }: OptionMenuProps) {
  const { t } = useTranslation('albumCard');
  const { openDialog, dismissDialog } = useDialog();

  const handleConfirmDelete = () => {
    onDelete()
      .then(
        () => {
          enqueueSnackbar({
            variant: 'success',
            message: t('delete.success'),
          });
        },
        () => {
          enqueueSnackbar({
            variant: 'error',
            message: t('delete.error'),
          });
        }
      )
      .finally(() => {
        dismissDialog();
        trackButtonClicked('Delete Album CTA Clicked', {
          page: 'Library',
          album_uuid: albumUuid,
        });
      });
  };

  const handleDelete = () => {
    openDialog({
      variant: 'destructive',
      title: t('delete.dialog.title'),
      message: t('delete.dialog.message'),
      onConfirm: handleConfirmDelete,
      onDismiss: dismissDialog,
    });
  };

  return (
    <BaseMenu
      button={<MoreOptionsButton $variant="tertiary" />}
      items={[
        {
          id: 'delete-album',
          label: t('options.delete'),
          onClick: handleDelete,
        },
      ]}
    />
  );
}
