import endOfDay from 'date-fns/endOfDay';
import formatRFC3339 from 'date-fns/formatRFC3339';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import subHours from 'date-fns/subHours';
import subMonths from 'date-fns/subMonths';

export const parseDate = (value: Date | string | undefined | null) => {
  if (value == null) return value;
  const parsed = new Date(value);

  return isNaN(+parsed) ? undefined : parsed;
};

export const getFormattedDate = (date: Date | string | undefined | null) => {
  const parsedDate = parseDate(date);

  if (parsedDate == null) return null;

  return parsedDate.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

export const roundDay = (date: Date) => {
  const rounded = new Date(date);
  rounded.setHours(0, 0, 0, 0);

  return rounded;
};

export const roundHour = (date: Date) => {
  const rounded = new Date(date);
  rounded.setMinutes(0, 0, 0);

  return rounded;
};

export const parseDateBeforeFilter = (dateBefore: string | undefined) => {
  const parsed = parseDate(dateBefore);

  if (parsed == null) return undefined;

  return formatRFC3339(endOfDay(parsed));
};

export const parseDateAfterFilter = (dateAfter: string | undefined) => {
  const parsed = parseDate(dateAfter);

  if (parsed != null) return formatRFC3339(startOfDay(parsed));

  switch (dateAfter) {
    case 'lastDay':
      return formatRFC3339(subHours(roundHour(new Date()), 24));
    case 'lastWeek':
      return formatRFC3339(subDays(roundDay(new Date()), 7));
    case 'lastMonth':
      return formatRFC3339(subMonths(roundDay(new Date()), 1));
    case 'allTime':
    default:
      return undefined;
  }
};

export function parseAndFormat(
  dateString: string | undefined | null,
  formatter: Intl.DateTimeFormat
) {
  const dateParsed = parseDate(dateString);

  return dateParsed != null ? formatter.format(dateParsed) : undefined;
}
