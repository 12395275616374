import { ErrorCode, useDropzone } from 'react-dropzone';
import { Dispatch, SetStateAction, useEffect } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { enqueueSnackbar } from 'notistack';
import { AddPhotoAlternateOutlined } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  ClearIcon,
  CloudDoneIcon,
  Divider,
  ErrorIcon,
  ScheduleIcon,
  Typography,
  UploadIcon,
} from '@/components/common';
import { ButtonMeero } from '@/components/common/ButtonMeero/ButtonMeero';
import {
  AddPhotoButton,
  DeleteIconButton,
  DragAndDropContainer,
  ImageContainer,
  ImagePreview,
  SelectedPhotosGrid,
  StatusIcon,
  UploadStatus,
} from './SelectPhotos.styles';

const statusIcons: Record<UploadStatus, React.ReactNode> = {
  'not-started': <ScheduleIcon fontSize="inherit" color="inherit" />,
  'in-progress': <CircularProgress size={35} sx={{ color: 'white' }} />,
  completed: <CloudDoneIcon fontSize="inherit" sx={{ color: 'white' }} />,
  error: <ErrorIcon color="error" fontSize="inherit" />,
};

export type UploadFileRequest = {
  file: File;
  uploadStatus: UploadStatus;
  destinationKey: string | null;
  imgSrc: string;
  size: string;
};

type SelectPhotosProps = {
  uploadRequests: UploadFileRequest[];
  setUploadRequests: Dispatch<SetStateAction<UploadFileRequest[]>>;
  readonly?: boolean;
  showUploadStatus?: boolean;
  hidden?: boolean;
};

const MAX_FILES = 30;
const MAX_FILE_SIZE_MB = 20; // 20MB

export function SelectPhotos({
  uploadRequests,
  setUploadRequests,
  readonly = false,
  showUploadStatus = false,
}: SelectPhotosProps) {
  const { t } = useTranslation('importPhotos');

  const {
    getRootProps,
    open: openFileSelection,
    getInputProps,
  } = useDropzone({
    disabled: readonly,
    noClick: true,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxFiles: MAX_FILES,
    maxSize: MAX_FILE_SIZE_MB * 1024 * 1024,
    onDrop(files, rej) {
      const errors = rej.flatMap((fileRej) =>
        fileRej.errors.flatMap((error) => error.code as ErrorCode)
      );

      if (
        errors.includes(ErrorCode.TooManyFiles) ||
        files.length + uploadRequests.length > MAX_FILES
      ) {
        enqueueSnackbar({
          title: t('errors.tooManyPhotos'),
          variant: 'warning',
          persist: true,
        });

        return;
      }

      if (errors.includes(ErrorCode.FileInvalidType)) {
        enqueueSnackbar({
          title: t('errors.invalidType'),
          variant: 'warning',
          persist: true,
        });

        return;
      }

      if (errors.includes(ErrorCode.FileTooLarge)) {
        enqueueSnackbar({
          title: t('errors.fileTooLarge', {
            maxSize: MAX_FILE_SIZE_MB,
          }),
          variant: 'warning',
          persist: true,
        });

        return;
      }

      const requests: UploadFileRequest[] = files.map((file) => ({
        file,
        uploadStatus: 'not-started',
        destinationKey: null,
        imgSrc: URL.createObjectURL(file),
        size: formatSize(file.size),
      }));
      setUploadRequests((prevRequests) => [...prevRequests, ...requests]);
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      uploadRequests.forEach((request) => URL.revokeObjectURL(request.imgSrc));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveUploadRequest = (imgSrc: string) => {
    setUploadRequests((prevRequests) =>
      prevRequests.filter((req) => req.imgSrc !== imgSrc)
    );
  };

  const canAddMorePhotos = !readonly && uploadRequests.length < MAX_FILES;

  return (
    <DragAndDropContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {uploadRequests.length === 0 ? (
        <EmptyState openFileSelection={openFileSelection} />
      ) : (
        <SelectedPhotosGrid>
          {canAddMorePhotos && (
            <AddPhotoButton onClick={openFileSelection}>
              <Typography
                component="div"
                variant="body2"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap={1}
              >
                <AddPhotoAlternateOutlined />
                <span>{t('addMore')}</span>
              </Typography>
            </AddPhotoButton>
          )}
          {uploadRequests.map(({ file, imgSrc, uploadStatus, size }, index) => (
            <ImageContainer
              key={index}
              $status={showUploadStatus ? uploadStatus : undefined}
              title={`${file.name} - ${size}`}
            >
              {!readonly && (
                <DeleteIconButton
                  size="small"
                  color="error"
                  onClick={() => handleRemoveUploadRequest(imgSrc)}
                >
                  <ClearIcon />
                </DeleteIconButton>
              )}
              <ImagePreview src={imgSrc} alt={file.name} fill />
              {showUploadStatus && (
                <StatusIcon $status={uploadStatus}>
                  {statusIcons[uploadStatus]}
                </StatusIcon>
              )}
            </ImageContainer>
          ))}
        </SelectedPhotosGrid>
      )}
    </DragAndDropContainer>
  );
}

type EmptyStateProps = {
  openFileSelection: () => void;
};

function EmptyState({ openFileSelection }: EmptyStateProps) {
  const { t } = useTranslation('importPhotos');

  return (
    <Box display="flex" flexDirection="column" rowGap={1.5} alignItems="center">
      <Image
        unoptimized
        src="/images/AddMedia.svg"
        width={129}
        height={104}
        alt=""
      />
      <Typography component="div" variant="subtitle1" fontWeight="bold">
        {t('dragAndDrop')}
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        gap={1}
      >
        <Divider sx={{ flexGrow: 1 }} />
        <Typography component="span" variant="body2" color="textSecondary">
          {t('or')}
        </Typography>
        <Divider sx={{ flexGrow: 1 }} />
      </Box>

      <ButtonMeero
        startIcon={<UploadIcon />}
        $type="secondary"
        onClick={openFileSelection}
      >
        {t('selectButton')}
      </ButtonMeero>
      <Typography
        component="div"
        variant="body2"
        color="textSecondary"
        textAlign="center"
      >
        {t('conditions')}
      </Typography>
    </Box>
  );
}

function formatSize(size: number) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];

  let unitIndex = 0;
  let sizeInUnit = size;
  while (sizeInUnit > 1024) {
    sizeInUnit /= 1024;
    unitIndex += 1;
  }

  return `${sizeInUnit.toFixed(1)} ${units[unitIndex]}`;
}
