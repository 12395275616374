import styled from '@/utils/styled';
import { Typography } from '@/components/common';
import { breakpoints, colors, fonts, getMeeSpacing } from '@/theme';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  min-height: 400px;
  gap: ${getMeeSpacing(3)};
  width: 100%;
`;
export const SvgContainer = styled('div')`
  position: relative;
  width: 300px;
  height: 180px;
`;
export const EmptyTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: ${getMeeSpacing(1.25)};
  max-width: 480px;
`;
export const Title = styled(Typography)`
  font-family: ${fonts.secondary};
`;
export const Subtitle = styled(Typography)`
  align-self: center;
  color: ${colors.miracle};
`;

export const StyledButtonImg = styled('button')`
  all: unset;
  margin-top: ${getMeeSpacing(4)};
  width: 200px;
  cursor: pointer;
  :focus-visible {
    outline: 1px solid ${colors.miracle50};
    padding: ${getMeeSpacing(1)};
    border-radius: 4px;
  }

  @media (min-width: ${breakpoints.md}px) {
    width: 300px;
  }
`;
