import React, { ReactElement } from 'react';

import {
  Container,
  EndText,
  IconContainer,
  TagIntegralType,
  TextContainer,
} from './TagIntegral.styles';

interface TagIntegralProps {
  content: string | ReactElement;
  type?: TagIntegralType;
  size?: 'small' | 'medium' | 'fit-content';
  iconSize?: 'small' | 'medium' | 'fit-content';
  isIconRight?: boolean;
  isEllipsis?: boolean;
  icon?: JSX.Element;
  onClick?: () => void;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  isBold?: boolean;
  darkMode?: boolean;
  endText?: string | null;
  hasBorder?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const TagIntegral = ({
  content,
  type = 'primary',
  size = 'medium',
  iconSize = 'medium',
  isIconRight = false,
  isEllipsis = false,
  icon,
  onClick,
  color,
  backgroundColor,
  borderColor,
  isBold = false,
  darkMode = false,
  hasBorder = true,
  endText,
  ...rest
}: TagIntegralProps) => {
  return (
    <Container
      onClick={onClick}
      $type={type}
      $size={size}
      $isReverse={isIconRight}
      $isEllipsis={isEllipsis}
      $color={color}
      $backgroundColor={backgroundColor}
      $borderColor={borderColor}
      $isBold={isBold}
      $darkMode={darkMode}
      $hasBorder={hasBorder}
      data-testid="tag container"
      onMouseEnter={rest.onMouseEnter}
      onMouseLeave={rest.onMouseLeave}
      {...rest}
    >
      {icon && (
        <IconContainer $size={iconSize} data-testid="tag icon">
          {icon}
        </IconContainer>
      )}
      <TextContainer $isEllipsis={isEllipsis}>{content}</TextContainer>
      {endText != null && <EndText>{endText}</EndText>}
    </Container>
  );
};
