import styled from '@/utils/styled';
import { colors } from '@/theme';
import { Checkbox } from '..';

export const CardCheckbox = styled(Checkbox)`
  height: 24px;
  width: 24px;
  border-radius: 10px;
  border: 1px solid ${colors.miracle};
  background-color: ${colors.gramShair};

  .MuiSvgIcon-root {
    opacity: 0;
  }

  &:hover {
    background-color: ${colors.gramShair};

    .MuiSvgIcon-root {
      opacity: 1;
    }
  }

  &.Mui-checked {
    border: unset;
    outline: unset;
    background-color: ${colors.primary};

    .MuiSvgIcon-root {
      opacity: 1;
    }
  }

  &.Mui-disabled {
    opacity: 0.3;
  }
`;
