import styled, { css } from '@/utils/styled';
import { colors, getMeeSpacing } from '@/theme';
import { CardActionArea } from '..';
import { CardCheckbox } from '../CardCheckbox/CardCheckbox.styles';

export const SelectCheckbox = styled(CardCheckbox)`
  position: absolute;
  z-index: 1;
  top: ${getMeeSpacing(1.5)};
  right: 14px;
  opacity: 0;
  border-radius: 6px;
`;

export const SELECTED_BORDER_WIDTH_PX = 4;
export const StyledCardActionArea = styled(CardActionArea)<{
  component: 'div';
  $albumShadow?: boolean;
  $isSelected?: boolean;
  $isSelecting?: boolean;
}>`
  position: relative;
  height: 100%;
  border-radius: 12px;
  cursor: pointer;

  ${({ $isSelecting = false }) =>
    $isSelecting &&
    css`
      ${SelectCheckbox}:not([aria-disabled="true"]) {
        opacity: 1;
      }
      ${SelectCheckbox} {
        opacity: 0.7;
      }
    `}

  ${({ $isSelected = false }) =>
    $isSelected &&
    css`
      outline: ${SELECTED_BORDER_WIDTH_PX}px solid ${colors.primary};
    `}

  &:hover {
    ${SelectCheckbox}:not([aria-disabled="true"]) {
      opacity: 1;
    }
    ${SelectCheckbox} {
      opacity: 0.7;
    }
  }

  ${({ $albumShadow = false, $isSelected = false }) =>
    $albumShadow &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 20px);
        height: 7px;
        background-color: ${$isSelected ? colors.primary : colors.infinity};
        margin: auto;
        border-radius: 0 0 8px 8px;
        transition:
          height ease-in-out 0.2s,
          top ease-in-out 0.2s;
        opacity: 0.16;
      }

      &::after {
        content: '';
        position: absolute;
        top: calc(100% + 7px);
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 40px);
        height: 7px;
        background-color: ${$isSelected ? colors.primary : colors.miracle};
        margin: auto;
        border-radius: 0 0 8px 8px;
        transition:
          height ease-in-out 0.2s,
          top ease-in-out 0.2s;
        opacity: 0.1;
      }

      &:hover {
        &::before {
          height: 9px;
        }
        &::after {
          top: calc(100% + 9px);
          height: 9px;
        }
      }
    `}
`;
