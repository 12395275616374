import React from 'react';
import Image from 'next/legacy/image';
import { useTranslation } from 'next-i18next';
import { TwicImg } from '@twicpics/components/react';
import { assets } from '@/config/assets-links';
import {
  Container,
  EmptyTextContainer,
  StyledButtonImg,
  Subtitle,
  SvgContainer,
  Title,
} from './EmptyMediaList.styles';

interface EmptyListProps {
  listType: 'default' | 'moderation' | 'enhancement' | 'home';
  contentType: 'media' | 'album';
  isFilterActive?: boolean;
  slot?: () => void;
}

export const EmptyMediaList = ({
  listType,
  contentType,
  isFilterActive = false,
  slot,
}: EmptyListProps) => {
  const { t } = useTranslation('common');

  const title = isFilterActive
    ? t(`emptyList.${listType}.afterSearch.title`, {
        contentType,
      })
    : t(`emptyList.${listType}.noContent.title`, {
        contentType,
      });

  const message = isFilterActive
    ? t(`emptyList.${listType}.afterSearch.message`, {
        contentType,
      })
    : t(`emptyList.${listType}.noContent.message`, {
        contentType,
      });

  return (
    <Container data-testid="empty list">
      {listType === 'home' ? (
        <StyledButtonImg onClick={slot}>
          <TwicImg
            src={assets.cdnImageAssets.homeEmptyState}
            mode="contain"
            alt="empty list"
            ratio={5}
          />
        </StyledButtonImg>
      ) : (
        <SvgContainer>
          <Image
            src="/images/AddMedia.svg"
            layout="fill"
            objectFit="contain"
            alt="empty list"
            priority
            unoptimized
          />
        </SvgContainer>
      )}

      <EmptyTextContainer>
        {listType === 'home' ? (
          <Title variant="h5" fontWeight={700}>
            {title}
          </Title>
        ) : (
          <Title variant="h5" color="textSecondary">
            {title}
          </Title>
        )}
        <Subtitle>{message}</Subtitle>
      </EmptyTextContainer>
    </Container>
  );
};
