import useDebounce from './use-debounce';

function getSearchString(search: string | undefined) {
  if (search === undefined || search.trim().length < 3) return undefined;

  return search;
}

export const useDebouncedSearch = (search: string | undefined) =>
  useDebounce(getSearchString(search), 500);
