import { ReactNode } from 'react';
import { SelectCheckbox, StyledCardActionArea } from './CardMediaSelect.styles';
import { Tooltip } from '../Tooltip/Tooltip';
import { CheckIcon } from '..';

type CardMediaSelectProps = {
  variant: 'album' | 'media';
  onClick?: () => void;
  children: ReactNode;
} & (
  | {
      selectable: boolean;
      isSelected: boolean;
      isSelecting: boolean;
      disabledTooltip: string | undefined;
      toggleSelection: (select: boolean) => void;
    }
  | {
      selectable: false;
      isSelected?: never;
      isSelecting?: never;
      disabledTooltip?: never;
      toggleSelection?: never;
    }
);

export function CardMediaSelect({
  variant,
  children,
  onClick,
  selectable,
  isSelected = false,
  isSelecting = false,
  disabledTooltip,
  toggleSelection,
}: CardMediaSelectProps) {
  const handleMediaClick = () => {
    if (isSelecting) {
      return toggleSelection?.(!isSelected);
    }

    return onClick?.();
  };

  return (
    <StyledCardActionArea
      component="div"
      $albumShadow={variant === 'album'}
      $isSelected={isSelected}
      $isSelecting={isSelecting}
      onClick={handleMediaClick}
    >
      {selectable && (
        <Tooltip title={disabledTooltip} placement="top">
          <SelectCheckbox
            color="primary"
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            icon={<CheckIcon fontSize="small" sx={{ color: 'white' }} />}
            checkedIcon={<CheckIcon fontSize="small" sx={{ color: 'white' }} />}
            onChange={(_, checked) => {
              toggleSelection(checked);
            }}
            checked={isSelected}
            aria-disabled={Boolean(disabledTooltip)}
          />
        </Tooltip>
      )}
      {children}
    </StyledCardActionArea>
  );
}
