/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Box, Divider, Typography } from '@/components/common';
import { Thumbnail } from '@/components/common/Thumbnail/Thumbnail';
import {
  SegmentedPicker,
  SegmentedPickerButton,
} from '@/components/common/SegmentedPicker/SegmentedPickers.styles';
import { Switch } from '@/components/common/Switch/Switch';
import { ImageComparisonSlider } from '@/components/common/ImageComparisonSlider/ImageComparisonSlider';
import { assets } from '@/config/assets-links';
import { PreviewContainer } from './SkyReplacement.styles';

type TimeOfDay = 'daylight' | 'sunset';
type SkyType = 'clear' | 'cloudy';

// Sky id format is defined by the API
type SkyId = `sky-${TimeOfDay}-${number}` | `sky-${TimeOfDay}_cloudy-${number}`;
const skies: Array<SkyId> = [
  'sky-daylight-1',
  'sky-daylight-2',
  'sky-daylight-3',
  'sky-daylight-4',
  'sky-daylight-5',
  'sky-daylight_cloudy-1',
  'sky-daylight_cloudy-2',
  'sky-daylight_cloudy-3',
  'sky-daylight_cloudy-4',
  'sky-daylight_cloudy-5',
  'sky-sunset-1',
  'sky-sunset-2',
  'sky-sunset-3',
  'sky-sunset-4',
  'sky-sunset-5',
  'sky-sunset-6',
  'sky-sunset_cloudy-1',
  'sky-sunset_cloudy-10',
  'sky-sunset_cloudy-2',
  'sky-sunset_cloudy-3',
  'sky-sunset_cloudy-4',
  'sky-sunset_cloudy-5',
  'sky-sunset_cloudy-6',
  'sky-sunset_cloudy-7',
  'sky-sunset_cloudy-8',
  'sky-sunset_cloudy-9',
];

export type Sky = {
  id: SkyId;
  timeOfDay: TimeOfDay;
  skyType: SkyType;
  skyS3Key: string;
  previewS3Key: string;
};

const SKIES_LIST = skies.map((id): Sky => {
  const [_, skyDescription] = id.split('-');
  const [timeOfDay, skyType] = skyDescription.split('_');

  return {
    id,
    timeOfDay: timeOfDay === 'sunset' ? 'sunset' : 'daylight',
    skyType: skyType === 'cloudy' ? 'cloudy' : 'clear',
    skyS3Key: `webapp/skies/${id}.jpg`,
    previewS3Key: `webapp/skies/previews/${id}.jpg`,
  };
});

type SkyReplacementProps = {
  selectedSky: Sky | undefined;
  setSelectedSky: (sky: Sky | undefined) => void;
};

export function SkyReplacement({
  selectedSky,
  setSelectedSky,
}: SkyReplacementProps) {
  const { t } = useTranslation('importPhotos');

  const [timeOfDay, setTimeOfDay] = useState<TimeOfDay>(
    selectedSky?.timeOfDay ?? 'daylight'
  );
  const [skyType, setSkyType] = useState<SkyType>(
    selectedSky?.skyType ?? 'clear'
  );

  const enabled = selectedSky !== undefined;

  const skySelection = useMemo(
    () =>
      SKIES_LIST.filter(
        (sky) => sky.skyType === skyType && sky.timeOfDay === timeOfDay
      ),
    [timeOfDay, skyType]
  );

  return (
    <Box
      display="flex"
      columnGap={5}
      justifyContent="space-between"
      overflow="auto"
      height="100%"
      flexDirection={['column', 'row']}
    >
      <Box
        display="flex"
        flexDirection="column"
        rowGap={2}
        minWidth={0}
        flexGrow={1}
      >
        <PreviewContainer>
          <ImageComparisonSlider
            firstImageS3Key={assets.cdnImageAssets.greySkyPreview}
            secondImageS3Key={selectedSky?.previewS3Key}
            twicImgProps={{ ratio: 3990 / 2992 }}
          />
        </PreviewContainer>
        <Box
          display="flex"
          sx={{ overflowX: 'auto' }}
          paddingBottom={1.5}
          flexShrink={0}
        >
          {skySelection.map((sky) => (
            <Thumbnail
              key={sky.id}
              s3Key={sky.skyS3Key}
              selected={enabled && selectedSky === sky}
              onClick={() => setSelectedSky(sky)}
              disabled={!enabled}
            />
          ))}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        rowGap={3}
        flexShrink={0}
        minWidth={230}
      >
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height={24}
          overflow="hidden"
        >
          <Typography variant="body2" fontWeight="bold">
            {t('skyReplacement.toggle')}
          </Typography>
          <Switch
            sx={{ marginRight: -1.5 }}
            checked={enabled}
            onChange={(_, checked) =>
              setSelectedSky(checked ? skySelection[0] : undefined)
            }
            disableRipple
          />
        </Box>
        <Divider />
        <Box>
          <Typography variant="h6" color="textSecondary" marginBottom={1}>
            {t('skyReplacement.timeOfTheDay')}
          </Typography>
          <SegmentedPicker
            value={timeOfDay}
            onChange={(_, value) => setTimeOfDay(value as TimeOfDay)}
          >
            <SegmentedPickerButton
              value="daylight"
              label={t('skyReplacement.daylight')}
              disabled={!enabled}
            />
            <SegmentedPickerButton
              value="sunset"
              label={t('skyReplacement.sunset')}
              disabled={!enabled}
            />
          </SegmentedPicker>
        </Box>
        <Box>
          <Typography variant="h6" color="textSecondary" marginBottom={1}>
            {t('skyReplacement.skyType')}
          </Typography>
          <SegmentedPicker
            value={skyType}
            onChange={(_, value) => setSkyType(value as SkyType)}
          >
            <SegmentedPickerButton
              value="clear"
              label={t('skyReplacement.clear')}
              disabled={!enabled}
            />
            <SegmentedPickerButton
              value="cloudy"
              label={t('skyReplacement.cloudy')}
              disabled={!enabled}
            />
          </SegmentedPicker>
        </Box>
      </Box>
    </Box>
  );
}
