import styled, { css } from '@/utils/styled';
import { colors, getMeeSpacing } from '@/theme';

export const Container = styled('div')<{ $isAlbumCard?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ $isAlbumCard = false }) => css`
    gap: ${$isAlbumCard ? getMeeSpacing(3) : getMeeSpacing(1.5)};
  `}
`;

export const CardMedia = styled('div')<{
  $isProcessing: boolean;
  $IMG_RATIO?: number;
}>`
  position: relative;
  aspect-ratio: ${({ $IMG_RATIO }) => $IMG_RATIO};

  user-select: none;

  ${({ $isProcessing }) =>
    $isProcessing &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${colors.blackAlpha50};
        border-radius: 12px;
        cursor: pointer;
      }
    `}

  &:hover > div {
    &::before {
      height: 7px;
    }
    &::after {
      top: calc(100% + 7px);
      height: 7px;
    }
  }
`;

export const CardContent = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const Row = styled('div')`
  display: flex;
  align-items: center;
  gap: ${getMeeSpacing(1.75)};
  justify-content: space-between;
  width: 100%;
`;
