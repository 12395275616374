import styled from '@/utils/styled';
import { Dialog } from '@/components/common';
import { colors, getMeeSpacing } from '@/theme';

export const StyledImportDialog = styled(Dialog)`
  & .MuiPaper-root {
    border-radius: 12px;
    background-color: ${colors.white};
  }
`;

export const DialogLayout = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${getMeeSpacing(5.5)};
  row-gap: ${getMeeSpacing(3)};
  height: 800px;
  overflow: hidden;
`;

export const TitleContainer = styled('div')`
  display: flex;
  column-gap: ${getMeeSpacing(2)};
  justify-content: space-between;
  align-items: center;
`;

export const DialogFooter = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StatusBarContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid ${colors.translucentWhite};
  height: 100px;
  padding: ${getMeeSpacing(0, 3)};
  border-radius: 12px;
  row-gap: ${getMeeSpacing(2)};
  flex-shrink: 0;
`;
