import Image from 'next/image';
import { Button, IconButton } from '@/components/common';
import { colors, getMeeSpacing } from '@/theme';
import styled, { css } from '@/utils/styled';

export const DragAndDropContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${getMeeSpacing(2)};
  border: 2px dashed ${colors.translucentWhite};
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
`;

export const SelectedPhotosGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(131px, 1fr));
  grid-auto-rows: max-content;
  grid-gap: ${getMeeSpacing(2)};
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;

  @keyframes passing-through {
    0% {
      opacity: 0;
      transform: translateY(40px);
    }

    30%,
    70% {
      opacity: 1;
      transform: translateY(0px);
    }

    100% {
      opacity: 0;
      transform: translateY(-40px);
    }
  }
`;

export const DeleteIconButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  color: ${colors.white};
  transition: opacity 0.3s ease-in-out;
`;

export const ImageContainer = styled('div')<{
  $status: UploadStatus | undefined;
}>`
  position: relative;
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid ${colors.translucentWhite};

  &:hover {
    ${DeleteIconButton} {
      opacity: 1;
    }
  }

  img {
    ${({ $status }) =>
      $status !== undefined &&
      $status !== 'completed' &&
      css`
        filter: brightness(0.7);
      `}
  }
`;

export const ImagePreview = styled(Image)`
  object-fit: cover;
  object-position: center;
  transition: filter 0.3s ease-in-out;
`;

export type UploadStatus =
  | 'not-started'
  | 'in-progress'
  | 'completed'
  | 'error';

export const StatusIcon = styled('div')<{ $status: UploadStatus }>`
  position: absolute;
  font-size: 40px;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  ${({ $status }) => {
    if ($status === 'not-started') {
      return css`
        opacity: 0.7;
        color: white;
      `;
    }

    if ($status === 'completed') {
      return css`
        animation: passing-through 2s cubic-bezier(0.77, 0, 0.175, 1);
      `;
    }

    return css`
      opacity: 1;
    `;
  }}
`;

export const AddPhotoButton = styled(Button)`
  display: flex;
  aspect-ratio: 1;
  text-transform: none;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 2px dashed ${colors.translucentWhite};
`;
