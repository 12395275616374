import { useSessionStorageValue } from '@react-hookz/web';

/**
 * Since router.back() acts like a browser back button, it is preferable to keep track of the parent page url.
 * This hook is meant to store, retrieve and remove a parent page url from session storage.
 * The storage key is generated from a combination of the target page name and a convenient id (albumUuid for album for instance).
 */
export function useParentPageUrl(targetPage: 'editor' | 'album', id: string) {
  const {
    value: parentPageUrl,
    remove: removeParentPageUrl,
    set: setParentPageUrl,
  } = useSessionStorageValue<string>(`parent-page-${targetPage}-${id}`);

  return { parentPageUrl, removeParentPageUrl, setParentPageUrl };
}
