import { TwicImg } from '@twicpics/components/react';
import { colors, fontWeight, getMeeSpacing } from '@/theme';
import styled, { css } from '@/utils/styled';

export const CardContainer = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: ${getMeeSpacing(3)};
`;

const IMG_RADIUS = 12;
export const RoundedTwicImg = styled(TwicImg)<{
  $showProcessing?: boolean;
  $darken?: boolean;
}>`
  border-radius: ${IMG_RADIUS}px;

  ${({ $showProcessing = false }) =>
    $showProcessing &&
    css`
      filter: opacity(0.6) grayscale(25%);
    `}

  ${({ $darken = false }) =>
    $darken &&
    css`
      filter: brightness(50%);
    `}
`;

export const CardDescription = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: ${getMeeSpacing(1)};
`;

export const AlbumNameHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${getMeeSpacing(1)};
`;

export const AlbumStatusLabel = styled('div')`
  position: absolute;
  bottom: 0;
  width: fit-content;
  padding: ${getMeeSpacing(0.5, 1)};
  margin: ${getMeeSpacing(2)};
  background-color: ${colors.secondary};
  border-radius: 60px;
  color: ${colors.white};
  font-weight: ${fontWeight.semiBold};
`;
