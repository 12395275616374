import React, { Dispatch, RefObject, SetStateAction, useState } from 'react';

import {
  BeforeTwicImg,
  Container,
  RevealContainer,
  StyledTwicImg,
  Switch,
  TransitionSpeed,
} from './ImageBeforeAfter.styles';

interface ImageBeforeAfterProps {
  s3KeyBefore: string;
  s3KeyAfter: string;
  alt: string;
  showBefore: boolean;
  setShowBefore: Dispatch<SetStateAction<boolean>>;
  /** When selecting we switch back to the after version and hide the toggle button */
  isSelecting?: boolean;
  trackToggleEvent?: (version: 'before' | 'after') => void;
  ratio: string | number;
  transitionSpeed?: TransitionSpeed;
}

/**
 * Since we mainly show the "after" picture by default, this component is
 * designed to reveal the before version. That is why the after version
 * is displayed first and the "reveal container" expands from right to left.
 */
export const ImageBeforeAfter = ({
  alt,
  showBefore,
  setShowBefore,
  isSelecting = false,
  s3KeyBefore,
  s3KeyAfter,
  ratio,
  transitionSpeed = 'default',
  trackToggleEvent,
}: ImageBeforeAfterProps) => {
  const [width, setWidth] = useState<number | undefined>();

  const showBeforeFinal = showBefore && !isSelecting;

  return (
    <Container>
      {!isSelecting && (
        <Switch
          showBefore={showBeforeFinal}
          toggleEvent={() => {
            setShowBefore((prev) => {
              trackToggleEvent?.(prev ? 'after' : 'before');

              return !prev;
            });
          }}
        />
      )}
      <StyledTwicImg
        src={s3KeyAfter}
        alt={`${alt} after enhancement`}
        mode="cover"
        ratio={ratio}
        onStateChange={({
          target,
        }: {
          target: RefObject<HTMLImageElement | undefined>;
        }) => {
          if (width === undefined && target.current) {
            setWidth(target.current.width);
          }
        }}
      />
      <RevealContainer
        $isRevealed={showBeforeFinal}
        $transitionSpeed={transitionSpeed}
      >
        <BeforeTwicImg
          src={s3KeyBefore}
          alt={`${alt} before enhancement`}
          mode="cover"
          ratio={ratio}
          $width={width}
        />
      </RevealContainer>
    </Container>
  );
};
