import { TwicImg } from '@twicpics/components/react';
import { Fade, Typography } from '@/components/common';
import {
  SelectThumbnailCheck,
  StyledCardAction,
  StyleThumbnail,
  StyleTitle,
} from './Thumbnail.styles';

type ThumbnailProps = {
  color?: 'primary' | 'secondary';
  s3Key: string;
  selected: boolean;
  onClick: () => void;
  title?: string;
  showSelector?: boolean;
  disabled?: boolean;
};

export function Thumbnail({
  color = 'primary',
  s3Key,
  selected,
  onClick,
  title,
  showSelector = false,
  disabled = false,
}: ThumbnailProps) {
  return (
    <StyledCardAction onClick={onClick} disabled={disabled}>
      {showSelector && (
        <Fade in={selected}>
          <SelectThumbnailCheck color={color} fontSize="small" />
        </Fade>
      )}
      <StyleThumbnail
        sx={(theme) => ({
          borderColor: theme.palette[color].main,
        })}
        $isSelected={selected}
      >
        <TwicImg src={s3Key} mode="cover" preTransform="quality=100" />
        {title !== undefined && (
          <StyleTitle>
            <Typography variant="body1" textAlign="center">
              {title}
            </Typography>
          </StyleTitle>
        )}
      </StyleThumbnail>
    </StyledCardAction>
  );
}
